export const shuffleArray = (array) =>
    [...array].sort(() => Math.random() - 0.5);

export const orderArray = (array) => [...array].sort();

export const getLink = (links, title) =>
    links.filter((link) => link.title === title)[0]?.link || 'http://ogmi.io';

export const shouldShow = (links, title) =>
    !links.filter((link) => link.title === title)[0]?.shouldHideIcon;
