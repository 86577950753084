import React from 'react';
import * as styles from './navigation.module.css';
import { getLink, shouldShow } from '../utils/utils';

const Navigation = ({ links }) => (
    <nav role="navigation" className={styles.container} aria-label="Main">
        <a href="/" className={styles.logoLink}>
            <span className={styles.logo} />
            <span className={styles.navigationItem}>Ogmi</span>
        </a>
        <ul className={styles.navigation}>
            <li className={styles.navigationItem + ' desktopOnly'}>
                { shouldShow(links, 'Whitepaper') && <a
                    href={getLink(links, 'Whitepaper')}
                    target="_blank"
                    rel="noreferrer"
                >
                    Whitepaper
                </a> } 
            </li>
            <li className={styles.navigationButton}>
                { shouldShow(links, 'Whitelist') && 
                <a
                    href={getLink(links, 'Whitelist')}
                    target="_blank"
                    rel="noreferrer"
                >
                    <span>Be Early 🌈</span>
                </a> }
            </li>
            <li className={styles.navigationButton}>
                { shouldShow(links, 'Discord') && 
                <a
                    href={getLink(links, 'Discord')}
                    target="_blank"
                    rel="noreferrer"
                >
                    <span>Join Discord 🌈</span>
                </a> }
            </li>
        </ul>
    </nav>
);

export default Navigation;
