import React from 'react';

import './variables.css';
import './global.css';
import Seo from './seo';
import Navigation from './navigation';
import Footer from './footer';

class Template extends React.Component {
    render() {
        const { links } = this.props;
        const { children } = this.props;

        return (
            <>
                <Seo />
                <Navigation links={links} />
                <main>{children}</main>
                <Footer links={links} />
            </>
        );
    }
}

export default Template;
